<template>
  <div
    class="page-title-area bg-1 about-view"
    :style="{
      'background-image': `linear-gradient(rgb(0, 0, 0, 0.18), rgb(0, 0, 0, 0)), url(${require('assets/project_images/pic/' +
        category +
        '-banner.jpg')})`,
    }"
  >
    <div class="container-fluid visitor-nav-parents">
      <div
        :class="[
          'page-title-content',
          { 'page-li-one-style': !breadCrumb[1] },
          { 'page-li-two-style': breadCrumb[1] },
        ]"
      >
        <ul>
          <li>
            <a :href="bindLang('/')">
              {{ $t("home") }}
            </a>
          </li>
          <li v-for="(item, index) in breadCrumb" :key="index">
            <router-link
              :to="bindLang(item.path)"
              v-if="item.path != undefined"
            >
              {{ item.name }}
            </router-link>
            {{ item.path == undefined ? item.name : "" }}
          </li>
        </ul>
      </div>
      <div class="mid-div">
        <div class="row clearfix">
          <div class="col-md-12">
            <div>
              <svg class="clip-svg hidden-sm-down" width="0" height="0">
                <defs>
                  <clipPath id="imgleft" clipPathUnits="objectBoundingBox">
                    <polygon points="0 0, 1 0, 0.8 1, 0 1"></polygon>
                  </clipPath>
                  <clipPath id="imgmid" clipPathUnits="objectBoundingBox">
                    <polygon points="0.2 0, 1 0, 0.8 1, 0 1"></polygon>
                  </clipPath>
                  <clipPath id="imgmid2" clipPathUnits="objectBoundingBox">
                    <polygon points="0.2 0, 1 0, 0.8 1, 0 1"></polygon>
                  </clipPath>
                  <clipPath id="imgright" clipPathUnits="objectBoundingBox">
                    <polygon points=".2 0, 1 0, 1 1, 0 1"></polygon>
                  </clipPath>
                </defs>
              </svg>
              <!--
                            <div class="mid-div row bt-row-threebox">
                                <div class="imageicon col-md-3 col-sm-6 col-xs-12 d-flex press-nav-1 align-items-end"
                                    id="img1" scrolling="no" :class="{'ogs-nav-is-active' : $route.path == '/news'}">
                                    <router-link to="/news">
                                        <div class="bt-fourbox-descr">
                                            <h2>{{ basicNavTitle[0] }}</h2>
                                            <p>{{ basicNavContent[0] }}</p>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="imageicon col-md-3 col-sm-6 col-xs-12 d-flex press-nav-2 align-items-end"
                                    id="img2" scrolling="no" :class="{'ogs-nav-is-active' : $route.path == '/press-album'}">
                                    <router-link to="/press-album">
                                        <div class="bt-fourbox-descr">
                                            <h2>{{ basicNavTitle[1] }}</h2>
                                            <p>{{ basicNavContent[1] }}</p>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="imageicon col-md-3 col-sm-6 col-xs-12 d-flex press-nav-3 align-items-end"
                                    id="img3" scrolling="no" :class="{'ogs-nav-is-active' : $route.path == '/document'}">
                                    <router-link to="/document">
                                        <div class="bt-fourbox-descr">
                                            <h2>{{ basicNavTitle[2] }}</h2>
                                            <p>{{ basicNavContent[2] }}</p>
                                        </div>
                                    </router-link>
                                </div>
                            </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
//import Rellax from 'rellax';
export default {
  name: "Header",
  props: {
    category: String,
    bannerTitle: String,
    breadCrumb: [],
  },
  mounted() {
    //new Rellax('.rellax');
    new WOW({
      live: false,
    }).init();
  },
  methods: {
    goToLastPage() {
      window.history.back();
    },
    bindLang: function(path) {
      return this.$i18n.bindLang(path);
    },
  },
};
</script>

<style scoped>
.page-title-area {
  background-size: cover;
  background-repeat: no-repeat;
}

.page-title-area::before {
  background-image: none;
  -webkit-animation: none;
  animation: none;
}

.page-title-content ul li {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.page-title-content.page-li-one-style ul li:nth-child(1) {
  cursor: pointer;
}
.page-title-content.page-li-one-style ul li:nth-child(2) {
  color: #ffff00;
}

.page-title-content.page-li-one-style ul li:nth-child(1)::before {
  color: #ffff00;
}

.page-title-content.page-li-one-style ul li:nth-child(2)::before {
  display: none;
}

/* 有第二層的時候 */
.page-title-content.page-li-two-style ul li:last-child {
  color: #ffff00;
}

.page-li-two-style ul li:nth-child(2)::before {
  content: "〉";
  color: #ffff00 !important;
}

.page-title-area .page-title-content ul li::before {
  content: "〉";
  position: absolute;
  top: 0px;
  right: 10px;
  background-color: transparent;
  width: 10px;
  height: 10px;
}

/* xs - Extra small devices (portrait phones, less than 576px) */
@media (max-width: 1200px) {
  .banner-text-color {
    font-size: 50px !important;
    line-height: 50px !important;
    color: #ffff00 !important;
    margin: 0 !important;
  }
}

/* sm - Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .banner-text-color {
    font-size: 130px !important;
    /*----by wei---*/
    line-height: 136px !important;
    /*----by wei---*/
    color: #ffff00 !important;
    margin: 0 auto !important;
    /* font-family: 'boxicons';----by wei--- */
    letter-spacing: 5px !important;
    /*----by wei---*/
  }
}

.mid-div {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  z-index: 5;
  bottom: 0;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

svg:not(:root) {
  overflow: hidden;
}

.clip-svg {
  position: absolute;
}

.bt-row-threebox {
  width: 117%;
  height: 240px;
}

.mid-div {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
}

.imageicon {
  z-index: 0;
  background-size: 100% 100%;
  transition: all 0.3s ease-in;
  border-top: none;
  background-color: #fff;
  padding: 0px;
  box-shadow: none;
}

.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.press-nav-1,
.press-nav-2,
.press-nav-3,
.press-nav-4 {
  background-color: transparent !important;
}

.press-nav-1:hover,
.press-nav-2:hover,
.press-nav-3:hover,
.press-nav-4:hover {
  background-color: #ffff00 !important;
}

.press-nav-1:hover h2,
.press-nav-2:hover h2,
.press-nav-3:hover h2,
.press-nav-4:hover h2 {
  color: #2680ff !important;
}

.press-nav-1:hover p,
.press-nav-2:hover p,
.press-nav-3:hover p,
.press-nav-4:hover p {
  color: #2680ff !important;
}

.press-nav-1 {
  left: 12%;
  -webkit-clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
  clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
}

.press-nav-2 {
  left: 7%;
  -webkit-clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
  clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
}

.press-nav-3 {
  left: 2%;
  -webkit-clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
  clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
}

.press-nav-4 {
  left: -12%;
  -webkit-clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
  clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
}

#features-area {
  background-color: #eff4f5;
}

.bt-fourbox-descr {
  text-align: left;
  padding-left: 8vw !important;
  padding-bottom: 1.4vw !important;
  flex-direction: column;
  color: #ffffff;
}

.bt-fourbox-descr h2 {
  color: #ffffff !important;
  font-weight: 100;
  text-shadow: 0px 0px 2px #101010a6;
  /*----by wei---*/
  font-size: 2rem;
}

.bt-fourbox-descr p {
  text-align: left;
  line-height: 1.3;
  color: #ffffff !important;
  font-size: 1rem;
  width: 75%;
}

@media screen and (max-width: 576px) {
  .bt-fourbox-descr h2 {
    font-size: 1.2rem;
  }

  .bt-fourbox-descr p {
    font-size: 0.7rem;
    padding-right: 0.2rem;
  }

  .imgright a .bt-fourbox-descr {
    padding-right: 0.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .mid-div {
    display: none;
  }

  .page-title-content ul {
    padding-bottom: 100px;
  }
}

.ogs-nav-is-active {
  background-color: #ffff00 !important;
}

.ogs-nav-is-active h2 {
  color: #2680ff !important;
}

.ogs-nav-is-active p {
  color: #2680ff !important;
}
</style>
