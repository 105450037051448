var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-title-area bg-1 about-view",
      style: {
        "background-image":
          "linear-gradient(rgb(0, 0, 0, 0.18), rgb(0, 0, 0, 0)), url(" +
          require("assets/project_images/pic/" + _vm.category + "-banner.jpg") +
          ")"
      }
    },
    [
      _c("div", { staticClass: "container-fluid visitor-nav-parents" }, [
        _c(
          "div",
          {
            class: [
              "page-title-content",
              { "page-li-one-style": !_vm.breadCrumb[1] },
              { "page-li-two-style": _vm.breadCrumb[1] }
            ]
          },
          [
            _c(
              "ul",
              [
                _c("li", [
                  _c("a", { attrs: { href: _vm.bindLang("/") } }, [
                    _vm._v(" " + _vm._s(_vm.$t("home")) + " ")
                  ])
                ]),
                _vm._l(_vm.breadCrumb, function(item, index) {
                  return _c(
                    "li",
                    { key: index },
                    [
                      item.path != undefined
                        ? _c(
                            "router-link",
                            { attrs: { to: _vm.bindLang(item.path) } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(item.path == undefined ? item.name : "") +
                          " "
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          ]
        ),
        _c("div", { staticClass: "mid-div" }, [
          _c("div", { staticClass: "row clearfix" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", [
                _c(
                  "svg",
                  {
                    staticClass: "clip-svg hidden-sm-down",
                    attrs: { width: "0", height: "0" }
                  },
                  [
                    _c("defs", [
                      _c(
                        "clipPath",
                        {
                          attrs: {
                            id: "imgleft",
                            clipPathUnits: "objectBoundingBox"
                          }
                        },
                        [
                          _c("polygon", {
                            attrs: { points: "0 0, 1 0, 0.8 1, 0 1" }
                          })
                        ]
                      ),
                      _c(
                        "clipPath",
                        {
                          attrs: {
                            id: "imgmid",
                            clipPathUnits: "objectBoundingBox"
                          }
                        },
                        [
                          _c("polygon", {
                            attrs: { points: "0.2 0, 1 0, 0.8 1, 0 1" }
                          })
                        ]
                      ),
                      _c(
                        "clipPath",
                        {
                          attrs: {
                            id: "imgmid2",
                            clipPathUnits: "objectBoundingBox"
                          }
                        },
                        [
                          _c("polygon", {
                            attrs: { points: "0.2 0, 1 0, 0.8 1, 0 1" }
                          })
                        ]
                      ),
                      _c(
                        "clipPath",
                        {
                          attrs: {
                            id: "imgright",
                            clipPathUnits: "objectBoundingBox"
                          }
                        },
                        [
                          _c("polygon", {
                            attrs: { points: ".2 0, 1 0, 1 1, 0 1" }
                          })
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }